import React from "react";
import rehmat from "../../../assets/images/Bg remove/12.png";
import lias from "../../../assets/images/Bg remove/11.png";
import ilyes from "../../../assets/images/Bg remove/13.png";
// import yavuz from "../../../assets/images/Bg remove/1.png";
import gLogo from "../../../assets/images/Bg remove/15 1.png";
import "./AboutBoardMembers.css";
import AboutRealEstateManager from "./AboutRealEstateManager";
import rehmatHover from "../../../assets/images/Bg remove/Rahmatullah-Rahimi.svg";
import IlyasHover from "../../../assets/images/Bg remove/Selahattin-Yavuz.svg";
// import yuavzHover from "../../../assets/images/Bg remove/Group 53.svg";
import liasHover from "../../../assets/images/Bg remove/Ilias-Rahimi.svg";
import catLogo from "../../../assets/images/Bg remove/cat.svg";
import { useTranslation } from "react-i18next";
import catHover from "../../../assets/images/Bg remove/mascotte.svg";

const AboutBoardMembers = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="about-board-member-sec">
        <div className="about-board-member-main">
          <h4>{t("boardTeamTitle")}</h4>
          <div className="about-board-member1">
            <img src={gLogo} alt="mem-logo" />
          </div>
          {/* <div className="about-board-member2">
            <img src={rehmat} alt="mem-logo" className="image1" />
            <img src={rehmatHover} alt="hover-logo" className="image2" />
          </div> */}
          <div className="about-board-member3 about-board-border1">
            <img src={ilyes} alt="mem-logo" className="image1" />
            <img src={IlyasHover} alt="mem-logo" className="image2" />
          </div>
          <div className="about-board-member4 ">
            <img src={lias} alt="mem-logo" className="image1" />
            <img src={liasHover} alt="mem-logo" className="image2" />
          </div>
          <div className="about-board-member5 about-board-border2">
            <img src={rehmat} alt="mem-logo" className="image1" />
            <img src={rehmatHover} alt="mem-logo" className="image2" />
          </div>
        </div>
      </div>
      <div className="about-realEstate-width">
        <div>
          <AboutRealEstateManager />
        </div>
        <div className="about-teamCat">
          <div className="about-teamCat-content">
            <p>Mascotte</p>
            <div className="about-teamCat-logo">
              <img src={catLogo} alt="cat-logo" className="image1" />
              <img src={catHover} alt="cat-logo" className="image2" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutBoardMembers;
